<template>
	<div>
		<Table :headers="employeeHeaders" title="Drivers">
			<div slot="header">
				<mdb-btn class="float-right" @click="showForm = true">
					Add Driver
				</mdb-btn>
			</div>
			<tbody slot="rows">
				<tr v-for="(driver, index) in drivers" :key="index">
					<td>
						<router-link
							:to="{
								path: `/companies/${location.companyId}/locations/${location.id}/employees/${driver.id}`
							}"
						>
							{{ driver.name }}
						</router-link>
					</td>
					<td>{{ driver.licenseId }}</td>
					<td>{{ driver.licenseExpireDate | dateFormat }}</td>
					<td>{{ driver.insuranceExpireDate | dateFormat }}</td>
					<td>{{ driver.status }}</td>
				</tr>
			</tbody>
		</Table>
		<mdb-modal :show="showForm" size="lg" remove-backdrop class="modal-dialog-scrollable" @close="closeModal()">
			<mdb-modal-header>
				<mdb-modal-title>Drivers Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<employee-form :company-id="location.companyId" :location-id="location.id" @saved="closeModal" />
			</mdb-modal-body>
		</mdb-modal>
	</div>
</template>

<script>
import {
	mdbBtn,
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalBody
} from 'mdbvue';
import Table from '@/components/Table';
import { locationFields } from '../models/location.model';
import EmployeeForm from './EmployeeForm';

export default {
	name: 'LocationDrivers',
	components: {
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		Table,
		EmployeeForm,
	},
	props: {
		location: {
			type: Object,
			default: function() {
				return { ...locationFields };
			}
		},
		drivers: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			showForm: false,
			employeeHeaders: ['Name', 'Drivers License #', 'License Expires', 'Insurance Expires', 'Status']
		};
	},
	methods: {
		closeModal() {
			this.showForm = false;
			this.$emit('refreshLocation')
		},
	},
};
</script>
<style></style>
