<template>
	<validated-form :message="formMessage" @submit="handleFormSubmit">
		<mdb-input
			v-model="dataFields.name"
			label="Employee Name"
			type="text"
			outline
			required
		/>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.email"
					label="Email"
					type="email"
					outline
				/>
			</mdbCol>
			<mdbCol>
				<mdb-input
					v-model="dataFields.phone"
					label="Phone"
					type="tel"
					outline
				/>
			</mdbCol>
		</mdbRow>
		<mdbRow>
			<mdbCol>
				<select v-model="dataFields.employmentType" class="browser-default custom-select md-form md-outline">
					<option value="">
						Employment Status
					</option>
					<option v-for="(employmentType, index) in employmentTypes" :key="index">
						{{ employmentType }}
					</option>
				</select>
			</mdbCol>
		</mdbRow>
		<mdb-input
			v-model="dataFields.licenseId"
			label="License Number"
			type="text"
			outline
			required
		/>
		<mdb-input
			v-model="dataFields.insuranceName"
			label="Insurance Name"
			type="text"
			outline
			required
		/>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.licenseExpireDate"
					label="License Expiration Date"
					type="date"
					outline
					required
				/>
			</mdbCol>
			<mdbCol>
				<mdb-input
					v-model="dataFields.insuranceExpireDate"
					label="Insurance Expiration Date"
					type="date"
					outline
					required
				/>
			</mdbCol>
		</mdbRow>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.birthDate"
					label="Date of Birth"
					type="date"
					outline
				/>
			</mdbCol>
			<mdbCol>
				<mdb-input
					v-model="dataFields.MVRDate"
					label="MVR Date"
					type="date"
					outline
				/>
			</mdbCol>
		</mdbRow>
		<mdb-input
			v-model="dataFields.streetAddress"
			label="Street Address"
			type="text"
			outline
		/>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.locality"
					label="City"
					type="text"
					outline
				/>
			</mdbCol>
			<mdbCol>
				<select v-model="dataFields.region" class="browser-default custom-select md-form md-outline">
					<option value="">
						Select a region
					</option>
					<option v-for="(region, index) in regions" :key="index">
						{{ region }}
					</option>
				</select>
			</mdbCol>
		</mdbRow>
		<mdb-input
			v-model="dataFields.postalCode"
			label="Postal Code"
			type="text"
			outline
		/>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.authorizedVehicleName"
					label="Authorized Vehicle 1"
					type="text"
					outline
				/>
			</mdbCol>
			<mdbCol>
				<mdb-input
					v-model="dataFields.authorizedVehicleModel"
					label="Authorized Vehicle Make/Model 1"
					type="text"
					outline
				/>
			</mdbCol>
		</mdbRow>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.authorizedVehicleName2"
					label="Authorized Vehicle 2"
					type="text"
					outline
				/>
			</mdbCol>
			<mdbCol>
				<mdb-input
					v-model="dataFields.authorizedVehicleModel2"
					label="Authorized Vehicle Make/Model 2"
					type="text"
					outline
				/>
			</mdbCol>
		</mdbRow>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.authorizedVehicleName3"
					label="Authorized Vehicle 3"
					type="text"
					outline
				/>
			</mdbCol>
			<mdbCol>
				<mdb-input
					v-model="dataFields.authorizedVehicleModel3"
					label="Authorized Vehicle Make/Model 3"
					type="text"
					outline
				/>
			</mdbCol>
		</mdbRow>
		<mdbRow>
			<mdbCol>
				<label for="employeeNotes">Notes</label>
				<textarea id="employeeNotes" v-model="dataFields.notes" class="form-control rounded-0" rows="3" />
			</mdbCol>
		</mdbRow>
		<div class="custom-control custom-checkbox">
			<input id="isMVRWatchCheck" v-model="dataFields.isMVRWatch" type="checkbox" class="custom-control-input" />
			<label class="custom-control-label" for="isMVRWatchCheck">Is MVR Watch?</label>
		</div>
		<div class="custom-control custom-checkbox">
			<input id="isApprovedCheck" v-model="dataFields.isApproved" type="checkbox" class="custom-control-input" />
			<label class="custom-control-label" for="isApprovedCheck">Is Approved?</label>
		</div>
		<mdbRow>
			<mdbCol>
				<mdb-btn type="submit" class="float-right">
					Save
				</mdb-btn>
			</mdbCol>
		</mdbRow>
		<br />
	</validated-form>
</template>

<script>
import ValidatedForm from '../ValidatedForm';
import { mdbInput, mdbBtn, mdbRow, mdbCol } from 'mdbvue';
import { mapService } from '../../services/map.service';

export default {
	name: 'PizzaAccountEmployeeForm',
	components: {
		mdbInput,
		mdbBtn,
		mdbRow,
		mdbCol,
		ValidatedForm
	},
	props: {
		dataFields: {
			type: Object,
			default: function() {
				return {};
			}
		},
		formMessage: {
			type: Object,
			default: function() {
				return {
					type: "danger",
					message: ""
				};
			}
		}
	},
	data: function() {
		return {
			regions: mapService.getUSStates() || [],
			employmentTypes: ['Full Time', 'Part Time', 'Temporary', 'Contract', 'Other'],
			useTypes: ['Personal', 'Business', 'Other'],
		};
	},
	methods: {
		async handleFormSubmit() {
			this.$emit('submit', this.dataFields);
		},
	},
};
</script>
