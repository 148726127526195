<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col class="justify-content-center">
					<mdb-card cascade narrow class="mt-5">
						<mdb-card-body>
							<mdb-card-title>Profile: {{ userProfile.email }}</mdb-card-title>
						</mdb-card-body>
					</mdb-card>
				</mdb-col>
			</mdb-row>
			<mdb-row>
				<mdb-col>
					<Table :headers="companyHeader" title="Accessible Companies">
						<div slot="header">
							<mdb-btn class="float-right" @click="showCompanyForm = true">
								Set Access
							</mdb-btn>
						</div>
						<tbody slot="rows">
							<tr v-for="(company, index) in accessibleCompanies" :key="index">
								<td>
									<router-link
										:to="{ path: `/companies/${company.id}` }"
									>
										{{ company.name }}
									</router-link>
								</td>
								<td>{{ company.locality }}</td>
								<td>{{ company.region }}</td>
							</tr>
						</tbody>
					</Table>
				</mdb-col>
			</mdb-row>
		</mdb-container>
		<mdb-modal
			:show="showCompanyForm"
			size="lg"
			remove-backdrop class="modal-dialog-scrollable"
			@close="closeModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>Company Access Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<company-multi-select-form :companies="companies" :current-companies="accessibleCompanies" @saved="saveAccess" />
				<br /><br /><br /><br />
				<br /><br /><br /><br />
				<br /><br /><br /><br />
			</mdb-modal-body>
		</mdb-modal>
	</div>
</template>

<script>
import Table from '@/components/Table';
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbBtn, mdbCardTitle, mdbCardBody, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody } from "mdbvue";
import { userProfileModel } from '../models/user-profile.model';
import CompanyMultiSelectForm from '../components/CompanyMultiSelectForm';
import { companyModel } from '../models/company.model';

export default {
	name: 'User',
	components: {
		mdbContainer,
		mdbRow,
		mdbBtn,
		mdbCol,
		mdbCard,
		mdbCardTitle,
		mdbCardBody,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		Table,
		CompanyMultiSelectForm
	},
	data () {
		return {
			userProfile: {},
			companyHeader: ['Name', 'City', 'State'],
			accessibleCompanies: [],
			companies: [],
			showCompanyForm: false
		};
	},
	created() {
		this.activeUserId = this.$route.params.userId;
		this.getUserProfile();
		this.readCompanies();
	},
	methods: {
		closeModal() {
			this.showCompanyForm = false;
		},
		async saveAccess(accessibleCompanies) {
			this.closeModal();
			await userProfileModel.upsert({
				id: this.activeUserId,
				accessibleCompanies
			});

			await this.getUserProfile();
		},
		getUserProfile() {
			userProfileModel.get(this.activeUserId)
				.then(async userProfile => {
					this.userProfile = userProfile;
					this.accessibleCompanies = await userProfileModel.getAccessibleCompanies(userProfile).catch(error => {
						console.log('LIST accessibleCompanies', error);

						return [];
					});
				});
		},
		readCompanies() {
			companyModel.list('name')
				.then(companies => {
					this.companies = companies;
				})
				.catch(error => {
					console.log('ERROR: company list', error)
				});
		}
	}
}
</script>
<style>

</style>
