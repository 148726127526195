<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col class="justify-content-center text-center">
					<mdbCard>
						<mdbCardBody>
							<h1>Page Not Found</h1>
						</mdbCardBody>
					</mdbCard>
				</mdb-col>
			</mdb-row>
		</mdb-container>
	</div>
</template>

<script>
import { mdbContainer, mdbCard, mdbCardBody, mdbRow, mdbCol } from "mdbvue";

export default {
	name: 'BadGateway',
	components: {
		mdbContainer,
		mdbCard,
		mdbCardBody,
		mdbRow,
		mdbCol
	},
	data () {
		return {
		};
	},
	computed: {
	},
	methods: {
	}
}
</script>
<style>

</style>
