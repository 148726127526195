import moment from 'moment';
import { fileService } from '../services/file.service';
import { emailModel } from '../models/email.model';
import { DTO } from './dto/database';

const collectionName = 'employees';
const fileBucket = 'employee_documents';

class EmployeeModel extends DTO {
	constructor(collection) {
		super(collection);
	}

	get(id){
		if (!id) {
			throw new Error('Employee id required');
		}

		return super.get(id)
			.then(employee => this.transformEmployeeResponse(employee));
	}

	list(orderBy, ...args){
		return super.list(orderBy, ...args)
			.then(employees => employees.map(employee => this.transformEmployeeResponse(employee)));
	}

	remove(userId) {
		return super.remove(userId)
			.then(() => this.readFiles(userId))
			.then(files => files.forEach(async file => {
				await this.deleteFile(userId, file.name);
			}));
	}

	listAllExpired() {
		return Promise.all([this.listExpired('license'), this.listExpired('insuranceExpireDate')])
			.then(([expiredLicenses, expiredInsurances]) => {
				const employeeInResultMap = {};
				const results = [];

				for (const expiredLicense of expiredLicenses) {
					if (!employeeInResultMap[expiredLicense.id]) {
						employeeInResultMap[expiredLicense.id] = true;
						results.push(expiredLicense);
					}
				}

				for (const expiredInsurance of expiredInsurances) {
					if (!employeeInResultMap[expiredInsurance.id]) {
						employeeInResultMap[expiredInsurance.id] = true;
						results.push(expiredInsurance);
					}
				}

				return results;
			});
	}

	listExpired(type = 'license') {
		const filterField = type === 'license' ? 'licenseExpireDate' : 'insuranceExpireDate';
		const currentTime = new Date();

		let lookup = this.getCollection()
			.where(filterField, '<=', currentTime)
			.where(filterField, '!=', false);

		return lookup.get()
			.then(querySnapshot => this.transformList(querySnapshot))
			.then(employees => {
				return employees.map(employee => this.transformEmployeeResponse(employee)).filter(employee => employee.isApproved === true);
			});
	}

	listPending() {
		return this.list('name', 'isApproved', '==', false);
	}

	transformEmployeeResponse(employee) {
		const currentTime = moment();
		employee.status = 'Pending';

		if (employee.isApproved) {
			employee.status = 'Approved';

			if (employee.licenseExpireDate) {
				const isLicenseExpired = moment(employee.licenseExpireDate.toDate());
				if (currentTime.isAfter(isLicenseExpired)) {
					employee.status = 'License Expired';
				}
			}

			if (employee.insuranceExpireDate) {
				const isInsuranceExpired = moment(employee.insuranceExpireDate.toDate());

				if (currentTime.isAfter(isInsuranceExpired)) {
					employee.status = 'Insurance Expired';
				}
			}
		}

		return employee;
	}

	readFiles(employeeId) {
		if (!employeeId) {
			throw new Error('Employee Id required');
		}

		return fileService.listFiles(`${fileBucket}/${employeeId}`);
	}

	deleteFile(employeeId, fileName){
		if (!employeeId) {
			throw new Error('Employee Id required');
		}

		if (!fileName) {
			throw new Error('File Name required');
		}

		return fileService.deleteFile(fileBucket, employeeId, fileName);
	}

	sendAdminUploadEmail(sentTo, employee) {
		const subject = `Sarkhosh Manager: Employee File Uploaded For ${employee.companyName}`;
		const employeeLink = `https://mydrivers.app/companies/${employee.companyId}/locations/${employee.locationId}/employees/${employee.id}`;

		const html = `
			A new file was uploaded for the company <b>${employee.companyName}</b> at location <b>${employee.locationName}</b>. <br /><br />
			Please review the employee:  <a href="${employeeLink}">${employee.name}</a>.
		`;

		emailModel.create({
			to: sentTo,
			message: {
				subject,
				html,
			},
		}).catch(error => {
			console.log('MAIL SEND ERROR', error);
		});
	}

	sendAdminNewDriverEmail(sentTo, employee) {
		const subject = `Sarkhosh Manager: Pending new driver approval for ${employee.companyName}`;
		const employeeLink = `https://mydrivers.app/companies/${employee.companyId}/locations/${employee.locationId}/employees/${employee.id}`;
		const html = `
			A new pending driver was created for the company <b>${employee.companyName}</b> at location <b>${employee.locationName}</b>. <br /><br />
			Please review the employee: <a href="${employeeLink}">${employee.name}</a>.
		`;

		emailModel.create({
			to: sentTo,
			message: {
				subject,
				html,
			},
		}).catch(error => {
			console.log('MAIL SEND ERROR', error);
		});
	}

	uploadFile(employee, file){
		if (!employee.id) {
			throw new Error('Employee Id required');
		}

		const fileName = file.name;
		return fileService.upload(fileBucket, employee.id, fileName, file)
			.then(response => {
				const sentTo = 'service@sarkhoshins.com';
				this.sendAdminUploadEmail(sentTo, employee);

				return response;
			});
	}
}

export const employeeModel = new EmployeeModel(collectionName);

export const employeeFields = {
	id: '',
	companyName: '',
	companyId: '',
	locationName: '',
	locationId: '',
	position: '',
	name: '',
	birthDate: '',
	MVRDate: '',
	insuranceExpireDate: '',
	insuranceName: '',
	licenseExpireDate: '',
	licenseId: '',
	authorizedVehicleName: '',
	authorizedVehicleModel: '',

	authorizedVehicleName2: '',
	authorizedVehicleModel2: '',

	authorizedVehicleName3: '',
	authorizedVehicleModel3: '',
	contactName: '',
	streetAddress: '',
	email: '',
	phone: '',
	locality: '',
	region: '',
	postalCode: '',
	isApproved: false,
	isMVRWatch: false,
	employmentType: '',
	useType: '',
	notes: '',
}
