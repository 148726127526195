<template>
	<mdb-container class="mt-5 p-3 pt-5">
		<mdb-row>
			<mdb-col>
				<company-overview :company="company" @refreshCompany="loadCompany" @deleteCompany="deleteCompany" />
			</mdb-col>
		</mdb-row>
		<mdb-row class="mb-3">
			<mdb-col>
				<company-locations :company="company" :locations="locations" @refreshCompany="loadCompany" />
			</mdb-col>
		</mdb-row>
	</mdb-container>
</template>

<script>
import {
	mdbContainer,
	mdbRow,
	mdbCol
} from "mdbvue";
import CompanyOverview from '../components/CompanyOverview';
import CompanyLocations from '../components/CompanyLocations';
import { companyModel } from '../models/company.model';

export default {
	name: 'CompanyProfile',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		CompanyOverview,
		CompanyLocations
	},
	data () {
		return {
			activeId: '',
			locations: [],
			company: {}
		};
	},
	created() {
		this.activeId = this.$route.params.companyId;
		this.loadCompany();
	},
	methods: {
		loadCompany() {
			Promise.all([this.readCompany(), this.readLocations()])
				.catch((error) => {
					console.log('ERROR: Load company', error);
				});
		},
		readCompany() {
			return companyModel.get(this.activeId)
				.then(company => {
					this.company = company;
				});
		},
		readLocations() {
			if (this.activeId) {
				return companyModel.readLocations(this.activeId)
					.then(locations => {
						this.locations = locations;
					});
			}

			return [];
		},

		deleteCompany() {
			return companyModel.remove(this.activeId)
				.then(() => this.$router.push('/companies'));
		}
	}
}
</script>
<style>

</style>
