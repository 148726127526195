import { DTO } from './dto/database';
import { employeeModel } from './employee.model';

const collectionName = 'locations';
class LocationModel extends DTO {
	constructor(collection) {
		super(collection);
	}

	readDrivers(locationId) {
		return employeeModel.list('name', 'locationId', '==', locationId)
			.catch(error => {
				console.log('ERROR: list locations', error);

				return [];
			});
	}

	remove(locationId) {
		return super.remove(locationId)
			.then(() => {
				return this.readDrivers(locationId)
					.then(drivers => drivers.forEach(async driver => await employeeModel.remove(driver.id)));
			});
	}
}

export const locationModel = new LocationModel(collectionName);

export const locationFields = {
	companyId: '',
	id: '',
	name: '',
	email: '',
	contactName: '',
	streetAddress: '',
	phone: '',
	locality: '',
	region: '',
	postalCode: '',
	notes: ''
}
