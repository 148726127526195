<template>
	<div class="sidebar-fixed position-fixed">
		<a class="logo-wrapper">
			<img alt="" class="img-fluid" src="../assets/S_logo_final.png" />
		</a>
		<mdb-list-group class="list-group-flush">
			<router-link to="/dashboard" @click.native="activeItem = 1">
				<mdb-list-group-item
					:action="true"
					:class="activeItem === 1 && 'active'"
				>
					<mdb-icon
						icon="home"
						class="mr-3"
					/>Reports
				</mdb-list-group-item>
			</router-link>
			<router-link to="/companies" @click.native="activeItem = 2">
				<mdb-list-group-item
					:action="true"
					:class="activeItem === 2 && 'active'"
				>
					<mdb-icon icon="building" class="mr-3" />Companies
				</mdb-list-group-item>
			</router-link>
			<router-link to="/profile" @click.native="activeItem = 4">
				<mdb-list-group-item
					:action="true"
					:class="activeItem === 4 && 'active'"
				>
					<mdb-icon icon="user" class="mr-3" />Profile
				</mdb-list-group-item>
			</router-link>
			<router-link v-if="userProfile.isAdmin" to="/users" @click.native="activeItem = 3">
				<mdb-list-group-item
					:action="true"
					:class="activeItem === 3 && 'active'"
				>
					<mdb-icon icon="users" class="mr-3" />Invite Users
				</mdb-list-group-item>
			</router-link>
			<router-link to="/general-guidelines" @click.native="activeItem = 5">
				<mdb-list-group-item
					:action="true"
					:class="activeItem === 5 && 'active'"
				>
					<mdb-icon icon="info-circle" class="mr-3" />General Guidelines
				</mdb-list-group-item>
			</router-link>
		</mdb-list-group>
	</div>
</template>

<script>
import {
	mdbIcon,
	mdbListGroup,
	mdbListGroupItem,
} from "mdbvue";

export default {
	name: "SideMenu",
	components: {
		mdbIcon,
		mdbListGroup,
		mdbListGroupItem,
	},
	props: {
		userProfile: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			activeItem: 1
		};
	},
	beforeMount() {
		const props = this.$route.matched[0].props;
		this.activeItem = (props.default || {}).page;
	},
	methods: {},
};
</script>

<style>

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 0;
}

.sidebar-fixed .logo-wrapper img {
  width: 100%;
  padding: 2.5rem;
}

.sidebar-fixed .list-group-item {
  display: block !important;
  transition: background-color 0.3s;
}

.sidebar-fixed .list-group .active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
}
</style>
