<template>
	<mdb-container class="mt-5 p-3 pt-5">
		<mdb-row>
			<mdb-col>
				<div class="mb-5">
					<router-link :to="{ path: locationLink }">
						<mdb-icon icon="arrow-left" size="lg" />
						Back to {{ location.name }} location
					</router-link>
				</div>
				<employee-overview :location="location" :employee="employee" @refreshEmployee="loadEmployee" @deleteEmployee="deleteEmployee" />
			</mdb-col>
		</mdb-row>
		<mdb-row class="mb-3">
			<mdb-col>
				<employee-files :employee="employee" :files="files" @reloadFiles="readFiles" />
			</mdb-col>
		</mdb-row>
	</mdb-container>
</template>

<script>
import {
	mdbContainer,
	mdbRow,
	mdbCol,
	mdbIcon
} from "mdbvue";
import EmployeeOverview from '../components/EmployeeOverview';
import EmployeeFiles from '../components/EmployeeFiles';
import { employeeModel } from '../models/employee.model';
import { locationModel } from '../models/location.model';
import '../services/file.service';

export default {
	name: 'EmployeeProfile',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		mdbIcon,
		EmployeeOverview,
		EmployeeFiles
	},
	data () {
		return {
			activeCompanyId: '',
			activeLocationId: '',
			activeEmployeeId: '',
			files: [],
			employee: {},
			location: {}
		};
	},
	computed: {
		locationLink: function() {
			return `/companies/${this.activeCompanyId}/locations/${this.activeLocationId}`;
		}
	},
	created() {
		this.activeCompanyId = this.$route.params.companyId;
		this.activeLocationId = this.$route.params.locationId;
		this.activeEmployeeId = this.$route.params.employeeId;
		this.loadEmployee();
	},
	methods: {
		loadEmployee() {
			return Promise.all([this.readEmployee(), this.readLocation(), this.readFiles()])
				.catch((error) => {
					console.log('ERROR: Load employee', error);
				});
		},
		readEmployee() {
			if (!this.activeEmployeeId) {
				return;
			}

			return employeeModel.get(this.activeEmployeeId)
				.then(employee => {
					this.employee = employee;
				}).catch(error => {
					console.log('ERROR: read employee', error);
				});
		},
		readLocation() {
			if (!this.activeLocationId) {
				return;
			}

			return locationModel.get(this.activeLocationId)
				.then(location => {
					this.location = location;
				}).catch(error => {
					console.log('ERROR: read location', error);
				});
		},

		readFiles() {
			if (!this.activeEmployeeId) {
				return;
			}

			return employeeModel.readFiles(this.activeEmployeeId)
				.then(files => {
					this.files = files;
				}).catch(error => {
					console.log('ERROR: read files', error);
				});
		},

		deleteEmployee(){
			if (!this.activeEmployeeId) {
				return;
			}

			return employeeModel.remove(this.activeEmployeeId)
				.then(() => {
					this.$router.push(this.locationLink);
				}).catch(error => {
					console.log('ERROR: delete employee', error);
				});
		}
	}
}
</script>
<style>

</style>
