<template>
	<validated-form @submit="handleFormSubmit">
		<div class="form-group">
			<label>Companies</label>
			<multiselect
				v-model="companySelections"
				placeholder="Search for a company"
				label="name"
				track-by="id"
				:options="companies"
				:multiple="true"
				:taggable="true"
			/>
		</div>
		<mdb-btn type="submit" class="float-right">
			Set
		</mdb-btn>
	</validated-form>
</template>
<script>
import Multiselect from "vue-multiselect";
import ValidatedForm from './ValidatedForm';
import { mdbBtn } from 'mdbvue';

export default {
	name: 'CompanyMultiSelectForm',
	components: {
		mdbBtn,
		Multiselect,
		ValidatedForm
	},
	props: {
		companies: {
			type: Array,
			default: function() {
				return [];
			}
		},
		currentCompanies: {
			type: Array,
			default: function() {
				return [];
			}
		},
	},
	data() {
		return {
			companySelections: [...this.currentCompanies]
		};
	},
	methods: {
		handleFormSubmit() {
			this.$emit('saved', this.companySelections);
		}
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
