import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import moment from 'moment';
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { Datetime } from 'vue-datetime'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)
Vue.component('datetime', Datetime);

firebase.initializeApp({
	apiKey: 'AIzaSyAwmJfRWNfDc3CYiopLBd-3uVF1v-YeSPA',
	authDomain: 'mydrivers.app',
	projectId: 'sarkhosh-insurance-manager',
	storageBucket: 'sarkhosh-insurance-manager.appspot.com',
	messagingSenderId: '880026910987',
	appId: '1:880026910987:web:38dcd865c49a725270a20e',
	measurementId: 'G-EW8JX1VN33'
});

Vue.config.productionTip = false
Vue.filter('dateFormat', function(dateObject) {
	let date = dateObject;
	if (typeof dateObject === 'object') {
		date = dateObject.toDate();
	}

	return moment(date).format('MMMM Do YYYY');
});

Vue.filter('dateTimeFormat', function(dateObject) {
	let date = dateObject;
	if (typeof dateObject === 'object') {
		date = dateObject.toDate();
	}

	return moment(date).format('MMMM Do YYYY hh:mm A');
});

let app;
firebase.auth().onAuthStateChanged(() => {
	if (!app) {
		/* eslint-disable no-new */
		app = new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app')
	}
});

//firebase.firestore().enablePersistence();
