<template>
	<mdb-navbar class="flexible-navbar" color="elegant-color-dark" position="top" dark scrolling>
		<mdb-navbar-brand href="/dashboard">
			<span class="d-none d-sm-block">My Drivers App – <span class="extra-header">Provided by Sarkhosh Insurance Agency</span></span>
			<span class="d-sm-none">My Drivers App</span>
		</mdb-navbar-brand>
		<mdb-navbar-toggler>
			<mdb-navbar-nav right>
				<mdb-dropdown v-if="user && user.email" mdb-dropdown tag="li" class="nav-item">
					<mdb-dropdown-toggle
						slot="toggle"
						tag="a"
						nav-link
						color="primary"
						waves-fixed
					>
						{{ user.displayName || user.email }}
					</mdb-dropdown-toggle>
					<mdb-dropdown-menu>
						<mdb-dropdown-item href="/dashboard">
							Reports
						</mdb-dropdown-item>
						<mdb-dropdown-item href="/companies">
							Companies
						</mdb-dropdown-item>
						<mdb-dropdown-item href="/profile">
							Profile
						</mdb-dropdown-item>
						<mdb-dropdown-item v-if="userProfile.isAdmin" href="/users">
							Invite Users
						</mdb-dropdown-item>
						<mdb-dropdown-item href="/general-guidelines">
							General Guidelines
						</mdb-dropdown-item>
						<mdb-dropdown-item @click="logout">
							Logout
						</mdb-dropdown-item>
					</mdb-dropdown-menu>
				</mdb-dropdown>
				<mdb-nav-item v-else mdb-nav-item href="/login" active>
					Login
				</mdb-nav-item>
			</mdb-navbar-nav>
		</mdb-navbar-toggler>
	</mdb-navbar>
</template>

<script>
import {
	mdbNavbar,
	mdbNavbarBrand,
	mdbNavbarToggler,
	mdbNavbarNav,
	mdbNavItem,
	mdbDropdown,
	mdbDropdownMenu,
	mdbDropdownToggle,
	mdbDropdownItem,
	waves
} from "mdbvue";
import { userService } from '../services/user.service';

export default {
	name: "TopMenu",
	components: {
		mdbNavbar,
		mdbNavbarBrand,
		mdbNavbarToggler,
		mdbNavbarNav,
		mdbNavItem,
		mdbDropdown,
		mdbDropdownMenu,
		mdbDropdownToggle,
		mdbDropdownItem
	},
	mixins: [waves],
	props: {
		user: {
			type: Object,
			default: function() {
				return {
					email: "",
					displayName: "",
				};
			}
		},
		userProfile: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	methods: {
		logout() {
			userService.logout()
				.finally(() => {
					window.location.reload();
				});
		},
	}
};
</script>

<style>
.elegant-color-dark {
	background-color: #000 !important;
}

.flexible-navbar {
  transition: padding-left 0.5s;
  padding-left: 300px;
}

@media (max-width: 1199.98px) {
  .flexible-navbar {
    padding-left: 10px;
  }
}

.extra-header {
	font-size: 1rem;
}

</style>
