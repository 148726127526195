<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col>
					<users-invite-overview :users="invitedUsers" :companies="companies" @savedUser="savedUser" />
				</mdb-col>
			</mdb-row>
		</mdb-container>
	</div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol } from "mdbvue";
import UsersInviteOverview from '../components/UsersInviteOverview';
import { invitedUsersModel } from '../models/invited-users.model';
import { companyModel } from '../models/company.model';

export default {
	name: 'UsersInvite',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		UsersInviteOverview
	},
	props: {
		userProfile: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data () {
		return {
			invitedUsers: [],
			companies: [],
		};
	},
	created() {
		if (!this.userProfile.isAdmin){
			this.$router.push('/dashboard');
		}

		this.getInvitedUsers();
		this.readCompanies();
	},
	methods: {
		getInvitedUsers() {
			invitedUsersModel.list('email')
				.then(users => {
					this.invitedUsers = users;
				});
		},
		readCompanies() {
			companyModel.list('name')
				.then(companies => {
					this.companies = companies;
				})
				.catch(error => {
					console.log('ERROR: company list', error)
				});
		},
		async savedUser(user) {
			const isAlreadyInvited = await invitedUsersModel.list('', 'email', '==', user.email).catch(error => {
				console.log('READ Invited users', error);

				return null;
			});

			if (isAlreadyInvited && isAlreadyInvited.length < 1) {
				invitedUsersModel.create(user)
					.then(() => {
						this.getInvitedUsers();
					})
					.catch(error => console.log('ERROR: invitedUsersModel create', error));
			}
		}
	}
}
</script>
<style>

</style>
