<template>
	<div class="flexible-content">
		<TopMenu :user="user" :user-profile="userProfile" />
		<SideMenu :user-profile="userProfile" />
		<main>
			<div class="mt-5">
				<router-view :user-profile="userProfile" @setUser="setUser()" />
			</div>
			<ftr color="primary-color-dark" class="text-center font-small darken-2">
				<p class="mb-0 py-3 text-center">
					&copy; {{ new Date().getFullYear() }} Copyright:
					<a href="https://www.sarkhoshins.com"> Sarkhosh Insurance </a>
					<br />
					2491 W. Shaw Ave. Suite 124, Fresno, CA 93711 - <a href="tel:+15592439000">(559) 243-9000</a> - FAX: <a href="tel:+15594498466">(559) 449-8466</a> - CA LIC 0E83633
					<br />
					<a href="https://www.loopsmarketing.com">Developed By Loops Marketing</a>
				</p>
			</ftr>
		</main>
	</div>
</template>

<script>
import TopMenu from "@/components/TopMenu";
import SideMenu from './components/SideMenu';
import { mdbFooter } from "mdbvue";
import { userService } from './services/user.service';
import { userProfileModel } from './models/user-profile.model';

export default {
	name: 'App',
	components: { SideMenu, TopMenu, ftr: mdbFooter },
	data() {
		return {
			user: {},
			userProfile: {},
			activeItem: 1
		}
	},
	async created() {
		await this.setUser();
		await this.readCurrentUserProfile();
	},
	methods: {
		async setUser() {
			const user = userService.getLoggedInUser();
			this.user = user;
			this.readCurrentUserProfile();
		},
		readCurrentUserProfile(){
			return userProfileModel.getActiveUserProfile()
				.then(userProfile => {
					window.userProfile = userProfile || {};
					this.userProfile = userProfile;
				});
		},
	}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import "./styles/buttons";

#app {
	text-align: center;
	margin-top: 150px;
}

main {
  background-color: #ededee;
}

.flexible-content {
  transition: padding-left 0.3s;
  padding-left: 270px;
}

@media (max-width: 1199.98px) {
  .flexible-content {
    padding-left: 0;
  }
}

</style>
