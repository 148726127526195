<template>
	<div>
		<mdb-card cascade narrow class="mt-5">
			<mdb-view class="gradient-card-header elegant-color-dark">
				<h4 class="h4-responsive text-white">
					Company: {{ company.name }}
				</h4>
			</mdb-view>
			<mdb-card-body>
				<div>
					<div class="float-right">
						<mdb-btn @click="showForm = true">
							Edit
						</mdb-btn>
						<mdb-btn color="secondary" @click="showDeleteConfirmation = true">
							Delete
						</mdb-btn>
					</div>
					<div>
						<h6>Account Type: <span>{{ (company.accountType && company.accountType.toUpperCase()) || 'PIZZA' }}</span></h6>
						<h6>Contact Name: <span>{{ company.contactName }}</span></h6>
						<h6>Email: <span><a :href="'mailto:'+ company.email">{{ company.email }}</a></span></h6>
						<h6>Phone: <span><a :href="'tel:+' + company.phone">{{ company.phone }}</a></span></h6>
						<h6>Address: <span>{{ company.streetAddress }} {{ company.locality }}, {{ company.region }} {{ company.postalCode }}</span></h6>
						<br />
					</div>
				</div>
			</mdb-card-body>
		</mdb-card>
		<mdb-modal
			:show="showForm"
			size="lg"
			remove-backdrop class="modal-dialog-scrollable"
			@close="closeModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>{{ company.name }} Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<CompanyForm :form-data="company" @saved="closeModal" />
			</mdb-modal-body>
		</mdb-modal>
		<mdb-modal
			:show="showDeleteConfirmation"
			size="lg"
			remove-backdrop class="modal-dialog-scrollable"
			@close="closeDeleteModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>Delete {{ company.name }} Data</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<p>
					Deleting the company {{ company.name }} will also delete all locations, employees and employee uploaded files. <br />
					Please make sure you have backups.
				</p>
			</mdb-modal-body>
			<mdb-modal-footer>
				<mdb-btn color="secondary" @click="closeDeleteModal()">
					Close
				</mdb-btn>
				<mdb-btn @click="deleteCompany()">
					Confirm
				</mdb-btn>
			</mdb-modal-footer>
		</mdb-modal>
	</div>
</template>

<script>
import {
	mdbCard,
	mdbCardBody,
	mdbView,
	mdbBtn,
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalFooter,
	mdbModalBody
} from "mdbvue";
import CompanyForm from './CompanyForm';
import { companyFields } from "@/models/company.model";

export default {
	name: "CompanyOverview",
	components: {
		mdbCard,
		mdbCardBody,
		mdbView,
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter,
		CompanyForm
	},
	props: {
		company: {
			type: Object,
			default: function() {
				return { ...companyFields };
			}
		}
	},
	data() {
		return {
			showForm: false,
			showDeleteConfirmation: false,
			formMessage: {},
		};
	},
	methods: {
		closeDeleteModal() {
			this.showDeleteConfirmation = false;
		},
		closeModal() {
			this.showForm = false;
			this.$emit('refreshCompany');
		},
		deleteCompany() {
			this.$emit('deleteCompany', this.company);
		}
	}
};
</script>
