import axios from 'axios';

export const mapService = {
	getLongLat,
	getUSStates
};

const apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
const apiKey = '';

function getLongLat(address) {
	if (!address) {
		return Promise.reject('address needed');
	}

	const url = `${apiUrl}?address=${address}&key=${apiKey}`;
	return axios.get(url).then(response => response.data.results[0]);
}

function getUSStates() {
	return [
		'AL',
		'AK',
		'AS',
		'AZ',
		'AR',
		'CA',
		'CO',
		'CT',
		'DE',
		'DC',
		'FM',
		'FL',
		'GA',
		'GU',
		'HI',
		'ID',
		'IL',
		'IN',
		'IA',
		'KS',
		'KY',
		'LA',
		'ME',
		'MH',
		'MD',
		'MA',
		'MI',
		'MN',
		'MS',
		'MO',
		'MT',
		'NE',
		'NV',
		'NH',
		'NJ',
		'NM',
		'NY',
		'NC',
		'ND',
		'MP',
		'OH',
		'OK',
		'OR',
		'PW',
		'PA',
		'PR',
		'RI',
		'SC',
		'SD',
		'TN',
		'TX',
		'UT',
		'VT',
		'VI',
		'VA',
		'WA',
		'WV',
		'WI',
		'WY'
	];
}
