<template>
	<div>
		<mdb-card cascade narrow class="mt-5">
			<mdb-view class="gradient-card-header elegant-color-dark">
				<h4 class="h4-responsive text-white">
					Location: {{ location.name }}
				</h4>
			</mdb-view>
			<mdb-card-body>
				<div>
					<div class="float-right">
						<mdb-btn @click="showForm = true">
							Edit
						</mdb-btn>
						<mdb-btn color="secondary" @click="showDeleteConfirmation = true">
							Delete
						</mdb-btn>
					</div>
					<div>
						<h5>Company: <span>{{ company.name }}</span></h5>
						<h6>Email: <span><a :href="'mailto:'+ location.email">{{ location.email }}</a></span></h6>
						<h6>Contact Name: <span>{{ location.contactName }}</span></h6>
						<h6>Phone: <span><a :href="'tel:+' + location.phone">{{ location.phone }}</a></span></h6>
						<h6>Address: <span>{{ location.streetAddress }} {{ location.locality }}, {{ location.region }} {{ location.postalCode }}</span></h6>
						<p>Notes: {{ location.notes }} </p>
					</div>
				</div>
			</mdb-card-body>
		</mdb-card>
		<mdb-modal
			:show="showForm"
			size="lg"
			remove-backdrop class="modal-dialog-scrollable"
			@close="closeModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>{{ location.name }} Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<location-form :company-id="location.companyId" :form-data="location" @saved="closeModal" />
			</mdb-modal-body>
		</mdb-modal>
		<mdb-modal
			:show="showDeleteConfirmation"
			size="lg"
			remove-backdrop class="modal-dialog-scrollable"
			@close="closeDeleteModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>Delete {{ location.name }} Data</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<p>
					Deleting the location will also delete all location employees and their uploaded files. <br />
					Please make sure you have backups.
				</p>
			</mdb-modal-body>
			<mdb-modal-footer>
				<mdb-btn color="secondary" @click="closeDeleteModal()">
					Close
				</mdb-btn>
				<mdb-btn @click="deleteLocation()">
					Confirm
				</mdb-btn>
			</mdb-modal-footer>
		</mdb-modal>
	</div>
</template>

<script>
import {
	mdbCard,
	mdbCardBody,
	mdbView,
	mdbBtn,
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalBody,
	mdbModalFooter
} from "mdbvue";
import LocationForm from './LocationForm';
import { companyFields } from '../models/company.model';
import { locationFields } from "@/models/location.model";

export default {
	name: "LocationOverview",
	components: {
		mdbCard,
		mdbCardBody,
		mdbView,
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter,
		LocationForm
	},
	props: {
		company: {
			type: Object,
			default: function() {
				return { ...companyFields };
			}
		},
		location: {
			type: Object,
			default: function() {
				return { ...locationFields };
			}
		}
	},
	data() {
		return {
			showForm: false,
			showDeleteConfirmation: false,
			formMessage: {},
		};
	},
	methods: {
		closeDeleteModal() {
			this.showDeleteConfirmation = false;
		},
		closeModal() {
			this.showForm = false;
			this.$emit('refreshLocation');
		},
		deleteLocation() {
			this.$emit('deleteLocation', this.location);
		}
	}
};
</script>
