import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

export class DTO {
	constructor(collection) {
		this.collection = collection;
	}

	randomString(length = 25) {
		return Array(length + 1)
			.join(
				Math.random()
					.toString(36)
					.slice(2, 18)
			)
			.slice(0, length);
	}

	getCurrentUserId() {
		return (firebase.auth().currentUser || {}).uid || '';
	}

	getCollection() {
		const db = firebase.firestore();
		return db.collection(this.collection);
	}

	formatDate(datetime) {
		if (!datetime) {
			return;
		}

		const date = datetime.toDate();
		return moment(date).format("MM/DD/YYYY");
	}

	get(id) {
		return this.getCollection()
			.doc(id)
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					data.createdAt = this.formatDate(data.createdAt);
					data.updatedAt = this.formatDate(data.updatedAt);
					return {
						...data,
						id: doc.id
					};
				}

				return Promise.reject("document not found");
			});
	}

	create(data) {
		const currentTime = new Date();
		return this.getCollection().add({
			...data,
			createdAt: currentTime,
			createdBy: this.getCurrentUserId(),
			updatedAt: currentTime
		});
	}

	updateArray(id, key, data) {
		return this.get(id).then(doc => {
			doc[key] = doc[key] || [];
			doc[key].push(data);
			return this.update(id, doc);
		});
	}

	update(id, data) {
		const currentTime = new Date();
		const docRef = this.getCollection().doc(id);
		delete data.createdAt;
		return docRef.update({
			...data,
			updatedAt: currentTime,
			updatedBy: this.getCurrentUserId()
		});
	}

	upsert(data) {
		let id = data && data.id;

		if (!id) {
			return this.create(data)
				.then(async response => {
					await this.upsert({
						id: response.id
					});

					return { ...data, id: response.id };
				});
		}

		if (id === 'userId') {
			id = this.getCurrentUserId();
		}

		const docRef = this.getCollection().doc(id);
		const currentTime = new Date();
		delete data.createdAt;

		return docRef.set({
			...data,
			updatedAt: currentTime,
			updatedBy: this.getCurrentUserId()
		}, { merge: true });
	}

	list(orderBy, ...args) {
		let lookup = args.length
			? this.getCollection().where(...args)
			: this.getCollection();

		if (orderBy) {
			lookup = lookup.orderBy(orderBy);
		}

		return lookup.get()
			.then(querySnapshot => this.transformList(querySnapshot));
	}

	remove(id) {
		if (!id) {
			return Promise.reject('id required');
		}

		const docRef = this.getCollection().doc(id);
		return docRef.delete();
	}

	transformList(querySnapshot){
		let results = [];
		querySnapshot.forEach(doc => {
			const data = doc.data();
			data.createdAt = this.formatDate(data.createdAt);
			data.updatedAt = this.formatDate(data.updatedAt);
			results.push({
				...data,
				id: doc.id
			});
		});

		return results;
	}
}
