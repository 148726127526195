<template>
	<div>
		<Table :headers="locationHeaders" title="Locations">
			<div slot="header">
				<mdb-btn class="float-right" @click="showForm = true">
					Add Location
				</mdb-btn>
			</div>
			<tbody slot="rows">
				<tr v-for="(location, index) in locations" :key="index">
					<td>
						<router-link
							:to="{
								path: `/companies/${company.id}/locations/${location.id}`
							}"
						>
							{{ location.name }}
						</router-link>
					</td>
					<td>{{ location.locality }}</td>
					<td>{{ location.region }}</td>
				</tr>
			</tbody>
		</Table>
		<mdb-modal :show="showForm" size="lg" remove-backdrop class="modal-dialog-scrollable" @close="closeModal()">
			<mdb-modal-header>
				<mdb-modal-title>Location Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<location-form :company-id="company.id" @saved="closeModal" />
			</mdb-modal-body>
		</mdb-modal>
	</div>
</template>

<script>
import {
	mdbBtn,
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalBody
} from 'mdbvue';
import Table from '@/components/Table';
import { companyFields } from '../models/company.model';
import LocationForm from './LocationForm';

export default {
	name: 'CompanyLocations',
	components: {
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		Table,
		LocationForm
	},
	props: {
		company: {
			type: Object,
			default: function() {
				return { ...companyFields };
			}
		},
		locations: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			showForm: false,
			locationHeaders: ['Name', 'City', 'State']
		};
	},
	methods: {
		closeModal() {
			this.showForm = false;
			this.$emit('refreshCompany')
		},
	},
};
</script>
<style></style>
