<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col>
					<Table :headers="companyHeader" title="Companies">
						<div slot="header">
							<mdb-btn v-if="userProfile.isAdmin" class="float-right" @click="showCompanyForm = true">
								Add Company
							</mdb-btn>
						</div>
						<tbody slot="rows">
							<tr v-for="(company, index) in companies" :key="index">
								<td>
									<router-link
										:to="{ path: `/companies/${company.id}` }"
									>
										{{ company.name }}
									</router-link>
								</td>
								<td>{{ company.locality }}</td>
								<td>{{ company.region }}</td>
								<td>{{ (company.accountType && company.accountType.toUpperCase()) || 'PIZZA' }}</td>
							</tr>
						</tbody>
					</Table>
				</mdb-col>
			</mdb-row>
		</mdb-container>
		<mdb-modal
			:show="showCompanyForm"
			size="lg"
			remove-backdrop class="modal-dialog-scrollable"
			@close="closeModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>Company Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<CompanyForm @saved="closeModal" />
			</mdb-modal-body>
		</mdb-modal>
	</div>
</template>

<script>
import {
	mdbContainer,
	mdbRow,
	mdbCol,
	mdbBtn,
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalBody } from "mdbvue";
import Table from '@/components/Table';
import CompanyForm from '@/components/CompanyForm';
import { userProfileModel } from '../models/user-profile.model';

export default {
	name: 'Companies',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		Table,
		CompanyForm
	},
	data () {
		return {
			userProfile: {},
			showCompanyForm: false,
			companyHeader: ['Name', 'City', 'State', 'Account Type'],
			companies: []
		};
	},
	created() {
		this.readCompanies();
		this.readCurrentUserProfile();
	},
	methods: {
		closeModal() {
			this.showCompanyForm = false;
			this.readCompanies();
		},
		readCurrentUserProfile(){
			return userProfileModel.getActiveUserProfile()
				.then(userProfile => {
					this.userProfile = userProfile;
				});
		},
		readCompanies() {
			userProfileModel.getActiveUserAccessibleCompanies()
				.then(companies => {
					this.companies = companies;
				})
				.catch(error => {
					console.log('ERROR: company list', error)
				});
		}
	}
}
</script>
<style>

</style>
