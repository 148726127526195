<template>
	<div>
		<Table :headers="userHeaders" title="Invited Users">
			<div slot="header">
				<mdb-btn class="float-right" @click="showForm = true">
					Invite User
				</mdb-btn>
			</div>
			<tbody slot="rows">
				<tr v-for="(user, index) in users" :key="index">
					<td v-if="user.userId">
						<router-link
							:to="{
								path: `/users/${user.userId}`
							}"
						>
							{{ user.email }}
						</router-link>
					</td>
					<td v-else>
						<span>{{ user.email }}</span>
					</td>
					<td v-if="user.userId">
						<span>Active</span>
					</td>
					<td v-else>
						<span>Invited</span>
					</td>
					<td class="float-right">
						<mdb-btn color="elegant" size="sm" @click="sendInvite(user.email)">
							Re-send Invite
						</mdb-btn>
					</td>
				</tr>
			</tbody>
		</Table>
		<mdb-modal :show="showForm" size="lg" remove-backdrop class="modal-dialog-scrollable" @close="closeModal">
			<mdb-modal-header>
				<mdb-modal-title>User Invite Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<users-invite-form :companies="companies" @saved="savedUser" />
				<br />
				<br /><br /><br /><br /><br />
			</mdb-modal-body>
		</mdb-modal>
	</div>
</template>

<script>
import {
	mdbBtn,
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalBody
} from 'mdbvue';
import Table from '@/components/Table';
import UsersInviteForm from './UsersInviteForm';
import { userService } from '../services/user.service';

export default {
	name: 'UsersInviteOverview',
	components: {
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		Table,
		UsersInviteForm,
	},
	props: {
		users: {
			type: Array,
			default: function() {
				return [];
			}
		},
		companies: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			showForm: false,
			userHeaders: ['Email', 'Status', 'Actions']
		};
	},
	methods: {
		closeModal() {
			this.showForm = false;
			this.$emit('refreshUsers');
		},
		savedUser(user){
			this.closeModal();
			this.$emit('savedUser', user);
		},
		sendInvite(email) {
			userService.sendEmailInvite(email)
				.then(() => {
					alert(`Invite to ${email} sent`);
				});
		}
	},
};
</script>
<style></style>
