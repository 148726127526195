<template>
	<div>
		<Table :headers="fileHeaders" title="Files" class="text-center">
			<div slot="header">
				<input ref="fileUpload" type="file" style="display:none" aria-describedby="inputGroupFile" @change="uploadFile" />
				<mdb-btn class="float-right" @click="$refs.fileUpload.click()">
					Upload
				</mdb-btn>
			</div>
			<tbody slot="rows">
				<tr v-for="(file, index) in files" :key="index">
					<td>
						<a :href="file.downloadUrl" target="_blank">
							{{ file.name }}
						</a>
					</td>
					<td>{{ file.metaData.timeCreated | dateTimeFormat }}</td>
					<td>
						<mdb-btn color="elegant" size="sm" @click="deleteFile(file.name)">
							Delete
						</mdb-btn>
					</td>
				</tr>
			</tbody>
		</Table>
	</div>
</template>

<script>
import Table from '@/components/Table';
import { mdbBtn } from 'mdbvue';
import { employeeFields, employeeModel } from '../models/employee.model';

export default {
	name: 'EmployeeFiles',
	components: {
		mdbBtn,
		Table
	},
	props: {
		employee: {
			type: Object,
			default: function() {
				return { ...employeeFields };
			}
		},
		files: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			fileHeaders: ['File Name', 'Upload Date', 'Actions']
		};
	},
	methods: {
		uploadFile() {
			const file = this.$refs.fileUpload.files[0];
			if (file) {
				employeeModel.uploadFile(this.employee, file)
					.then(() => {
						this.$emit('reloadFiles');
					})
					.catch(error => {
						alert('Error uploading file', error.message);

						console.log('ERROR: file upload', error);
					});
			}
		},

		deleteFile(fileName) {
			if (fileName) {
				employeeModel.deleteFile(this.employee.id, fileName)
					.then(() => {
						this.$emit('reloadFiles');
					})
					.catch(error => {
						alert('Error deleting file', error.message);

						console.log('ERROR: file delete', error);
					});
			}
		}
	},
};
</script>
<style></style>
