<template>
	<div>
		<Table :headers="employeeHeaders" :title="title">
			<div v-if="employees.length" slot="header">
				<div class="float-right">
					<mdb-btn @click="generateReport">
						Download Report
					</mdb-btn>
				</div>
			</div>
			<tbody slot="rows">
				<tr v-for="(employee, index) in employees" :key="index">
					<td>
						<router-link
							:to="{
								path: `/companies/${employee.companyId}/locations/${employee.locationId}/employees/${employee.id}`
							}"
						>
							{{ employee.name }}
						</router-link>
					</td>
					<td>
						<router-link
							:to="{
								path: `/companies/${employee.companyId}`
							}"
						>
							{{ employee.companyName }}
						</router-link>
					</td>
					<td>
						<router-link
							:to="{
								path: `/companies/${employee.companyId}/locations/${employee.locationId}`
							}"
						>
							{{ employee.locationName }}
						</router-link>
					</td>
					<td>{{ employee.licenseId }}</td>
					<td><span :class="isDateExpired(employee.licenseExpireDate) ? 'text-danger' : ''">{{ employee.licenseExpireDate | dateFormat }}</span></td>
					<td><span :class="isDateExpired(employee.insuranceExpireDate) ? 'text-danger' : ''">{{ employee.insuranceExpireDate | dateFormat }}</span></td>
					<td>{{ employee.locality }}</td>
					<td>{{ employee.region }}</td>
					<td>{{ employee.status }}</td>
				</tr>
			</tbody>
		</Table>
	</div>
</template>

<script>
import { mdbBtn } from "mdbvue";
import moment from 'moment';
import Table from '@/components/Table';
import { reportService } from '../services/report.service';

export default {
	name: 'EmployeeTable',
	components: {
		mdbBtn,
		Table,
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		employees: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			employeeHeaders: ['Name', 'Company', 'Location', 'Driver License', 'Driver License Expires', 'Insurance Expires', 'City', 'State', 'Status'],
		};
	},
	methods: {
		cleanEmployee(employee) {
			delete employee.companyId;
			delete employee.id;
			delete employee.updatedBy;
			delete employee.createdBy;
			delete employee.locationId;

			return employee;
		},
		generateReport(){
			const date = new Date();
			const today = moment(date).format("YYYY-MM-DD");
			const title = `employee-report-${this.title.replace(' ', '-')}-${today}`.toLowerCase();
			const headers = Object.keys(this.cleanEmployee(this.employees[0]));
			const reportData = [headers];

			for (const employee of this.employees) {
				this.cleanEmployee(employee)

				const employeeData = [];
				for (const header of headers) {
					let data = employee[header];
					if (typeof data === 'object') {
						const date = data.toDate();
						data = moment(date).format("YYYY-MM-DD");
					}

					employeeData.push(data);
				}

				reportData.push(employeeData);
			}

			return reportService.exportToCsv(`${title}.csv`, reportData);
		},
		isDateExpired: function(dateObject) {
			let date = dateObject;
			if (typeof dateObject === 'object') {
				date = dateObject.toDate();
			}

			return moment(date) < moment();
		}
	}
};
</script>
<style></style>
