import { DTO } from './dto/database';
import { locationModel } from './location.model';

const collectionName = 'companies';

class CompanyModel extends DTO {
	constructor(collection) {
		super(collection);
	}

	readLocations(companyId) {
		return locationModel.list('name', 'companyId', '==', companyId)
			.catch(error => {
				console.log('ERROR: list locations', error);

				return [];
			});
	}

	remove(companyId){
		return super.remove(companyId)
			.then(() => this.readLocations(companyId))
			.then(locations => {
				return locations.forEach(async location => {
					await locationModel.remove(location.id);
				});
			});
	}
}

export const companyModel = new CompanyModel(collectionName);

export const companyFields = {
	id: '',
	name: '',
	email: '',
	contactName: '',
	streetAddress: '',
	phone: '',
	locality: '',
	region: '',
	postalCode: '',
	accountType: '',
}
