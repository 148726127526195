<template>
	<validated-form :message="formMessage" @submit="handleFormSubmit">
		<select v-model="dataFields.accountType" class="browser-default custom-select" required>
			<option value="">
				Assign an account type
			</option>
			<option v-for="(accountType, index) in accountTypes" :key="index">
				{{ accountType }}
			</option>
		</select>
		<mdb-input
			v-model="dataFields.name"
			label="Name"
			type="text"
			outline
			required
		/>
		<mdb-input
			v-model="dataFields.email"
			label="Contact Email"
			type="email"
			outline
			required
		/>
		<mdb-input
			v-model="dataFields.contactName"
			label="Contact Name"
			type="text"
			outline
			required
		/>
		<mdb-input
			v-model="dataFields.phone"
			label="Phone"
			type="tel"
			outline
			required
		/>
		<mdb-input
			v-model="dataFields.streetAddress"
			label="Street Address"
			type="text"
			outline
		/>
		<mdb-input
			v-model="dataFields.locality"
			label="City"
			type="text"
			outline
		/>
		<select v-model="dataFields.region" class="browser-default custom-select">
			<option value="">
				Select a region
			</option>
			<option v-for="(region, index) in regions" :key="index">
				{{ region }}
			</option>
		</select>
		<mdb-input
			v-model="dataFields.postalCode"
			label="Postal Code"
			type="text"
			outline
		/>
		<mdb-btn type="submit" class="float-right">
			Save
		</mdb-btn>
	</validated-form>
</template>

<script>
import ValidatedForm from './ValidatedForm';
import { mdbInput, mdbBtn } from 'mdbvue';
import { companyModel, companyFields } from '../models/company.model';
import { mapService } from '../services/map.service';

export default {
	name: 'CompanyForm',
	components: {
		mdbInput,
		mdbBtn,
		ValidatedForm
	},
	props: {
		formData: {
			type: Object,
			default: function() {
				return { ...companyFields };
			}
		}
	},
	data() {
		return {
			regions: mapService.getUSStates() || [],
			accountTypes: ['pizza', 'auto dealer'],
			formMessage: {},
			dataFields: { ...this.formData }
		};
	},
	methods: {
		handleFormSubmit() {
			this.formMessage = {};

			companyModel
				.upsert(this.dataFields)
				.then(() => {
					this.formMessage = {
						type: 'success',
						message: 'Company Saved!'
					};

					this.dataFields = { ...companyFields };
					this.$emit('saved', this.dataFields);
				})
				.catch(error => {
					this.formMessage = {
						type: 'danger',
						message: error.message || 'There was a problem updating. Please try again'
					};
				});
		}
	}
};
</script>
