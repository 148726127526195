<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col class="justify-content-center">
					<mdb-card cascade narrow class="mt-5">
						<mdb-card-body>
							<mdb-card-title class="mb-3">
								General Guidelines
							</mdb-card-title>
							<mdb-card-text>Evaluate the MVR for the prior 36 months of activity.</mdb-card-text>
							<mdb-tbl responsive class="table guide-line-table text-center" text-black bordered>
								<mdb-tbl-head class="bg-info">
									<tr>
										<th># of Minor Violations</th>
										<th colspan="4">
											Number of At-Fault Accidents
										</th>
										<th>Major Violations</th>
									</tr>
								</mdb-tbl-head>
								<mdb-tbl-body>
									<tr class="bg-header">
										<td />
										<td>0</td>
										<td>1</td>
										<td>2</td>
										<td>3</td>
										<td>1 or more</td>
									</tr>
									<tr>
										<td class="bg-info">
											0
										</td>
										<td>Approved</td>
										<td>Approved</td>
										<td class="bg-warning">
											MVR Watch
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
									</tr>

									<tr>
										<td class="bg-info">
											1
										</td>
										<td>Approved</td>
										<td>Approved</td>
										<td class="bg-warning">
											MVR Watch
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
									</tr>
									<tr>
										<td class="bg-info">
											2
										</td>
										<td>Approved</td>
										<td class="bg-warning">
											MVR Watch
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
									</tr>
									<tr>
										<td class="bg-info">
											3
										</td>
										<td class="bg-warning">
											MVR Watch
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
									</tr>
									<tr>
										<td class="bg-info">
											4
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
										<td class="bg-danger">
											Exclude
										</td>
									</tr>
								</mdb-tbl-body>
							</mdb-tbl>

							<mdb-card-text>Drivers that fall into the <span class="bg-warning">MVR Watch</span> category should be pulled semi-annually. There are circumstances where the underwriter may wish to exclude a driver in that category due to the nature of the accident and/or violations on the MVR.</mdb-card-text>
							<div>
								<h6 class="font-weight-bold">
									Major Violations Include:
								</h6>
								<ol>
									<li>Driving under the influence of drugs or alcohol</li>
									<li>Road rage</li>
									<li>Reckless driving, negligent homicide or aggravated assault with a motor vehicle</li>
									<li>Driving while license is suspended</li>
									<li>Speed contest</li>
									<li>Hit and run accident</li>
									<li>Speed > 30mph over the limit (general guideline if speed is known)</li>
								</ol>
							</div>
							<div>
								<h6 class="font-weight-bold">
									Other Considerations/Operations:
								</h6>
								<ul>
									<li>Minimum acceptable driver age for vehicle requiring CDL is 21 and at least 2 years of CDL driving experience.</li>
									<li>Minimum driver age for Dump Trucks or Concrete Mixed In-Transit is 25 with 2 years of CDL experience.</li>
									<li>Delivery operations for Pizza/Restaurant/Retail have their own guidelines. Drivers must be at least 18 years of age, and 18 months of driving experience.</li>
								</ul>
							</div>
							<mdb-card-text>These are specific to Oregon Mutual, however should be used as a general guideline for all carriers.</mdb-card-text>
						</mdb-card-body>
					</mdb-card>
				</mdb-col>
			</mdb-row>
		</mdb-container>
	</div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardTitle, mdbCardBody, mdbCardText, mdbTbl, mdbTblHead, mdbTblBody } from "mdbvue";

export default {
	name: 'GeneralGuidelines',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardTitle,
		mdbCardBody,
		mdbCardText,
		mdbTbl,
		mdbTblHead,
		mdbTblBody
	},
	data () {
		return {

		};
	},
}
</script>
<style lang="scss" scoped>
@import "../styles/variables.scss";

.card-text {
	color: #000 !important;
}

.guide-line-table td, .guide-line-table th {
	font-weight: bold;
}

.guide-line-table tr {
	margin-bottom: 0px !important;
}

.bg-danger {
	background-color: $primary-color;
}

.bg-warning {
	background-color: #ffbb33;
}

.bg-info {
	background-color: #B7C6E4;
}

.bg-header {
	background-color: #EAB28B;
}
</style>
