<template>
	<div>
		<mdb-card cascade narrow class="mt-5">
			<mdb-view class="gradient-card-header elegant-color-dark">
				<h4 class="h4-responsive text-white">
					Driver: {{ employee.name }}
				</h4>
			</mdb-view>
			<mdb-card-body>
				<div>
					<div class="float-right">
						<mdb-btn @click="showForm = true">
							Edit
						</mdb-btn>
						<mdb-btn color="secondary" @click="showDeleteConfirmation = true">
							Delete
						</mdb-btn>
					</div>
					<div>
						<h5><b>Location:</b> <span>{{ location.name }}</span></h5>
						<h6><b>Name: </b><span>{{ employee.name }}</span></h6>
						<h6><b>Phone: </b><span><a :href="'tel:+' + employee.phone">{{ employee.phone }}</a></span></h6>
						<h6><b>Email: </b><span><a :href="'mailto:'+ employee.email">{{ employee.email }}</a></span></h6>
						<h6 v-if="employee.position">
							<b>Position: </b><span>{{ employee.position || '' }}</span>
						</h6>
						<h6 v-if="employee.streetAddress">
							<b>Address: </b><span>{{ employee.streetAddress }} {{ employee.locality }}, {{ employee.region }} {{ employee.postalCode }}</span>
						</h6>
						<h6 v-if="employee.birthDate">
							<b>Date of Birth: </b><span v-if="employee.birthDate">{{ employee.birthDate | dateFormat }}</span>
						</h6>
						<h6 v-if="employee.employmentType">
							<b>Employment Status: </b><span>{{ employee.employmentType || '' }}</span>
						</h6>
						<h6 v-if="employee.useType">
							<b>Car Use Type: </b><span>{{ employee.useType || '' }}</span>
						</h6>
						<br />
						<h6 v-if="employee.authorizedVehicleName">
							<b>Authorized Vehicle Name 1: </b><span>{{ employee.authorizedVehicleName }}</span>
						</h6>
						<h6 v-if="employee.authorizedVehicleModel">
							<b>Authorized Vehicle Model 1: </b><span>{{ employee.authorizedVehicleModel }}</span>
						</h6>
						<br v-if="employee.authorizedVehicleName2 || employee.authorizedVehicleModel2" />
						<h6 v-if="employee.authorizedVehicleName2">
							<b>Authorized Vehicle Name 2: </b><span>{{ employee.authorizedVehicleName2 }}</span>
						</h6>
						<h6 v-if="employee.authorizedVehicleModel2">
							<b>Authorized Vehicle Model 2: </b><span>{{ employee.authorizedVehicleModel2 }}</span>
						</h6>
						<br v-if="employee.authorizedVehicleName3 || employee.authorizedVehicleModel3" />
						<h6 v-if="employee.authorizedVehicleName3">
							<b>Authorized Vehicle Name 3: </b><span>{{ employee.authorizedVehicleName3 }}</span>
						</h6>
						<h6 v-if="employee.authorizedVehicleModel3">
							<b>Authorized Vehicle Model 3: </b><span>{{ employee.authorizedVehicleModel3 }}</span>
						</h6>
						<br />
						<h6><b>License Number: </b><span>{{ employee.licenseId }}</span></h6>
						<h6><b>License Expire Date: </b><span v-if="employee.licenseExpireDate">{{ employee.licenseExpireDate | dateFormat }}</span></h6>
						<br />
						<h6 v-if="employee.insuranceName">
							<b>Insurance Name: </b><span>{{ employee.insuranceName }}</span>
						</h6>
						<h6 v-if="employee.insuranceExpireDate">
							<b>Insurance Expire Date: </b><span>{{ employee.insuranceExpireDate | dateFormat }}</span>
						</h6>
						<h6 v-if="employee.MVRDate">
							<b>MVR Date: </b><span>{{ employee.MVRDate | dateFormat }}</span>
						</h6>
						<h6 v-if="employee.notes">
							<b>Notes: </b><span>{{ employee.notes }}</span>
						</h6>
						<br />
						<h6><b>Status: </b><span>{{ employee.status }}</span></h6>
						<h6 v-if="employee.isMVRWatch">
							<b>Is MVR Watch? </b><span>{{ !!employee.isMVRWatch }}</span>
						</h6>
					</div>
				</div>
			</mdb-card-body>
		</mdb-card>
		<mdb-modal
			class="modal-dialog-scrollable"
			:show="showForm"
			size="lg"
			remove-backdrop
			@close="closeModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>{{ employee.name }} Form</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<employee-form :company-id="location.companyId" :location-id="employee.locationId" :form-data="employee" @saved="closeModal" />
			</mdb-modal-body>
		</mdb-modal>
		<mdb-modal
			:show="showDeleteConfirmation"
			size="lg"
			remove-backdrop class="modal-dialog-scrollable"
			@close="closeDeleteModal()"
		>
			<mdb-modal-header>
				<mdb-modal-title>Delete {{ employee.name }} Data</mdb-modal-title>
			</mdb-modal-header>
			<mdb-modal-body>
				<p>
					Deleting the employee will also delete all the uploaded files. <br />
					please make sure you have backups.
				</p>
			</mdb-modal-body>
			<mdb-modal-footer>
				<mdb-btn color="secondary" @click="closeDeleteModal()">
					Close
				</mdb-btn>
				<mdb-btn @click="deleteEmployee()">
					Confirm
				</mdb-btn>
			</mdb-modal-footer>
		</mdb-modal>
	</div>
</template>

<script>
import {
	mdbCard,
	mdbCardBody,
	mdbView,
	mdbBtn,
	mdbModal,
	mdbModalHeader,
	mdbModalTitle,
	mdbModalBody,
	mdbModalFooter
} from "mdbvue";
import EmployeeForm from './EmployeeForm';
import { employeeFields } from '../models/employee.model';
import { locationFields } from '@/models/location.model';

export default {
	name: "EmployeeOverview",
	components: {
		mdbCard,
		mdbCardBody,
		mdbView,
		mdbBtn,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter,
		EmployeeForm
	},
	props: {
		location: {
			type: Object,
			default: function() {
				return { ...locationFields };
			}
		},
		employee: {
			type: Object,
			default: function() {
				return { ...employeeFields };
			}
		}
	},
	data() {
		return {
			showForm: false,
			showDeleteConfirmation: false,
			formMessage: {},
		};
	},
	methods: {
		closeDeleteModal() {
			this.showDeleteConfirmation = false;
		},
		closeModal() {
			this.showForm = false;
			this.$emit('refreshEmployee');
		},
		deleteEmployee() {
			this.$emit('deleteEmployee', this.employee);
		}
	}
};
</script>
