var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Table',{attrs:{"headers":_vm.employeeHeaders,"title":_vm.title}},[(_vm.employees.length)?_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"float-right"},[_c('mdb-btn',{on:{"click":_vm.generateReport}},[_vm._v(" Download Report ")])],1)]):_vm._e(),_c('tbody',{attrs:{"slot":"rows"},slot:"rows"},_vm._l((_vm.employees),function(employee,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":{
							path: `/companies/${employee.companyId}/locations/${employee.locationId}/employees/${employee.id}`
						}}},[_vm._v(" "+_vm._s(employee.name)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
							path: `/companies/${employee.companyId}`
						}}},[_vm._v(" "+_vm._s(employee.companyName)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
							path: `/companies/${employee.companyId}/locations/${employee.locationId}`
						}}},[_vm._v(" "+_vm._s(employee.locationName)+" ")])],1),_c('td',[_vm._v(_vm._s(employee.licenseId))]),_c('td',[_c('span',{class:_vm.isDateExpired(employee.licenseExpireDate) ? 'text-danger' : ''},[_vm._v(_vm._s(_vm._f("dateFormat")(employee.licenseExpireDate)))])]),_c('td',[_c('span',{class:_vm.isDateExpired(employee.insuranceExpireDate) ? 'text-danger' : ''},[_vm._v(_vm._s(_vm._f("dateFormat")(employee.insuranceExpireDate)))])]),_c('td',[_vm._v(_vm._s(employee.locality))]),_c('td',[_vm._v(_vm._s(employee.region))]),_c('td',[_vm._v(_vm._s(employee.status))])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }