<template>
	<section class="mt-5 p-3 pt-5">
		<mdb-row>
			<mdb-col col="12" class="d-flex justify-content-center">
				<mdbCard class="login_form_container">
					<mdbCardBody>
						<form v-if="showLoginForm" @submit.prevent="login()">
							<p class="h4 text-center mb-4">
								My Drivers App
							</p>
							<div class="grey-text">
								<mdbInput
									v-model="email"
									v-model.trim="email"
									label="Your email"
									icon="envelope"
									type="email"
									outline
								/>
								<mdbInput
									v-model="password"
									v-model.trim="password"
									label="Your password"
									icon="lock"
									type="password"
									outline
								/>
								<span v-show="error" class="red-text">{{ error }}</span>
							</div>
							<div class="text-center">
								<mdb-btn size="lg" class="login-button" type="submit">
									Login
								</mdb-btn>
								<div v-show="message">
									<br />
									<span class="green-text">{{ message }}</span>
								</div>
								<hr />
								<a href="javascript:void(0)" @click="showLoginForm = false">Password Reset</a>
							</div>
						</form>
						<form v-else @submit.prevent="sendPasswordReset()">
							<p class="h4 text-center mb-4">
								My Drivers App Password Reset
							</p>
							<div class="grey-text">
								<mdbInput
									v-model="email"
									v-model.trim="email"
									label="Your email"
									icon="envelope"
									type="email"
									outline
								/>
								<span v-show="error" class="red-text">{{ error }}</span>
							</div>
							<div class="text-center">
								<mdb-btn size="lg" class="login-button" type="submit">
									Send Password Reset
								</mdb-btn>
								<hr />
								<a href="javascript:void(0)" @click="showLoginForm = true">Login</a>
							</div>
						</form>
					</mdbCardBody>
				</mdbCard>
			</mdb-col>
		</mdb-row>
	</section>
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody, mdbRow, mdbCol } from "mdbvue";
import { userService } from "../services/user.service";

export default {
	name: "Login",
	components: {
		mdbCard,
		mdbBtn,
		mdbInput,
		mdbCardBody,
		mdbRow,
		mdbCol
	},
	data() {
		return {
			showLoginForm: true,
			email: "",
			password: "",
			error: "",
			message: ""
		};
	},
	methods: {
		login() {
			return userService
				.login(this.email, this.password)
				.then(() => {
					this.$router.push('/');
					this.$emit('setUser');
				})
				.catch(error => (this.error = error.message));
		},
		sendPasswordReset() {
			return userService
				.sendPasswordReset(this.email)
				.then(() => {
					this.message = 'Please check your email to reset your password!'
					this.showLoginForm = true;
				})
				.catch(error => (this.error = error.message));
		}
	}
};
</script>
<style scoped lang="scss">

.login_form_container {
	width: 650px;
}
</style>
