import firebase from "firebase/app";
import "firebase/auth";

export const userService = {
	login,
	logout,
	createUser,
	getLoggedInUser,
	sendPasswordReset,
	sendEmailInvite,
	signInWithEmailLink
};

function login(email, password) {
	if (email && password) {
		return firebase
			.auth()
			.signInWithEmailAndPassword(email.trim(), password.trim());
	}

	return Promise.reject({ message: "Please review your email and password" });
}

function signInWithEmailLink(email, url) {
	if (firebase.auth().isSignInWithEmailLink(url)) {
		return firebase.auth().signInWithEmailLink(email, url);
	}

	return Promise.reject(`${url} is not an invite link`);
}

function logout() {
	return firebase.auth().signOut();
}

function getLoggedInUser() {
	return firebase.auth().currentUser;
}

function createUser(email, password) {
	return firebase.auth().createUserWithEmailAndPassword(email, password);
}

function sendPasswordReset(email) {
	return firebase.auth().sendPasswordResetEmail(email);
}

function sendEmailInvite(email){
	let tempEmail = email.toLowerCase();

	return firebase.auth().sendSignInLinkToEmail(tempEmail, {
		url: `${window.location.origin}/users-invited?email=${encodeURIComponent(tempEmail)}`,
		handleCodeInApp: true
	});
}
