import moment from 'moment';
import firebase from "firebase/app";
import "firebase/storage";

export const fileService = {
	upload,
	deleteUserFiles,
	deleteFile,
	listFiles,
	listRecentFiles
};

function readReference(bucketPath) {
	return firebase.app().storage().ref(bucketPath);
}

function listFiles(bucketPath) {
	return readReference(bucketPath).listAll()
		.then(files => transformFiles(files));
}

function listRecentFiles(bucketPath) {
	return readReference(bucketPath).listAll()
		.then(files => transformFiles(files));
}

function upload(bucketName, userId, fileName, file) {
	const generatedFileName = generateFileName(fileName);
	const filePath = `${bucketName}/${userId}/${generatedFileName}`;

	return readReference(filePath).put(file);
}

function deleteUserFiles(bucketName, userId) {
	if (!userId) {
		return;
	}

	const filePath = `${bucketName}/${userId}`;

	return readReference(filePath).delete();
}

function deleteFile(bucketName, userId, fileName) {
	const filePath = `${bucketName}/${userId}/${fileName}`;

	return readReference(filePath).delete();
}

function generateFileName(fileName) {
	const fileNameParts = fileName.split('.');
	const extension = fileNameParts.pop();
	const timestamp = Date.now();

	return `${fileNameParts.join('')}-${timestamp}.${extension}`;
}

function sortFiles(fileA, fileB) {
	const metadataA = fileA.metaData || {};
	const timeCreatedA = moment(metadataA.timeCreated || '');

	const metadataB = fileB.metaData || {};
	const timeCreatedB = moment(metadataB.timeCreated || '');

	if (timeCreatedA > timeCreatedB) {
		return -1;
	}

	if (timeCreatedA < timeCreatedB) {
		return 1;
	}

	return 0;
}

async function transformFiles(files) {
	const fileResponses = [];

	for (const file of files.items) {
		const downloadUrl = await file.getDownloadURL();
		const metaData = await file.getMetadata();

		fileResponses.push({
			downloadUrl,
			name: file.name,
			metaData
		});
	}

	return fileResponses.sort(sortFiles);
}
