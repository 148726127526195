<template>
	<validated-form :message="formMessage" @submit="handleFormSubmit">
		<mdb-input
			v-model="dataFields.password"
			label="Password"
			type="password"
			outline
		/>
		<mdb-input
			v-model="dataFields.displayName"
			label="Name"
			type="text"
			outline
		/>
		<div class="custom-control custom-checkbox">
			<input id="sendReminderEmails" v-model="dataFields.sendReminderEmails" type="checkbox" class="custom-control-input" />
			<label class="custom-control-label" for="sendReminderEmails">Receive Reminder Emails?</label>
		</div>

		<mdb-btn type="submit" class="float-right">
			Save
		</mdb-btn>
	</validated-form>
</template>

<script>
import ValidatedForm from './ValidatedForm';
import { mdbInput, mdbBtn } from 'mdbvue';
import { userService } from '../services/user.service';

export default {
	name: 'UserForm',
	components: {
		mdbInput,
		mdbBtn,
		ValidatedForm
	},
	props: {
		currentUser: {
			type: Object,
			required: true,
			default: function() {
				return {};
			}
		},
		sendReminderEmails: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			formMessage: {},
			dataFields: {
				displayName: this.currentUser.displayName ? this.currentUser.displayName: '',
				sendReminderEmails: this.sendReminderEmails,
			}
		};
	},
	watch: {
		sendReminderEmails: function(newVal) {
			this.dataFields.sendReminderEmails = newVal;
		}
	},
	methods: {
		handleFormSubmit() {
			this.formMessage = {};
			const promise = this.dataFields.displayName ? this.currentUser.updateProfile({ displayName: this.dataFields.displayName }) : Promise.resolve();
			return promise
				.then(() => {
					if (this.dataFields.password) {
						this.currentUser.updatePassword(this.dataFields.password)
							.then(() => userService.login(this.currentUser.email, this.dataFields.password));
					}
				})
				.then(async () => {
					this.formMessage = {
						type: 'success',
						message: 'User Saved!'
					};

					this.$emit('saved', this.dataFields);
				})
				.catch(error => {
					this.formMessage = {
						type: 'danger',
						message: error.message || 'There was a problem updating. Please try again'
					};
				});
		}
	}
};
</script>
