<template>
	<validated-form :message="formMessage" @submit="handleFormSubmit">
		<mdb-input
			v-model="dataFields.name"
			label="Employee Name"
			type="text"
			outline
			required
		/>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.email"
					label="Email"
					type="email"
					outline
				/>
			</mdbCol>
			<mdbCol>
				<mdb-input
					v-model="dataFields.phone"
					label="Phone"
					type="tel"
					outline
				/>
			</mdbCol>
		</mdbRow>
		<mdb-input
			v-model="dataFields.position"
			label="Position"
			type="text"
			outline
			required
		/>
		<mdbRow>
			<mdbCol>
				<select v-model="dataFields.employmentType" required class="browser-default custom-select md-form md-outline">
					<option value="">
						Employment Status
					</option>
					<option v-for="(employmentType, index) in employmentTypes" :key="index">
						{{ employmentType }}
					</option>
				</select>
			</mdbCol>
			<mdbCol>
				<select v-model="dataFields.useType" required class="browser-default custom-select custom-select-90 md-form md-outline">
					<option value="">
						Car use type?
					</option>
					<option v-for="(useType, index) in useTypes" :key="index">
						{{ useType }}
					</option>
				</select>
				<mdb-tooltip trigger="hover" :options="{ placement: 'left' }">
					<span slot="tip">Personal = Furnished use. <br />Furnished use is for family members, owners or employees who regularly take an inventory vehicle home <br /><br />Business = Non-furnished use.<br />Non-furnished is for those driving for business only</span>
					<span slot="reference">
						&nbsp;<i slot="reference" class="fas fa-question-circle" />
					</span>
				</mdb-tooltip>
			</mdbCol>
		</mdbRow>
		<mdb-input
			v-model="dataFields.licenseId"
			label="License Number"
			type="text"
			outline
			required
		/>
		<mdbRow>
			<mdbCol>
				<mdb-input
					v-model="dataFields.licenseExpireDate"
					label="License Expiration Date"
					type="date"
					outline
					required
				/>
			</mdbCol>
		</mdbRow>
		<mdbRow>
			<mdbCol>
				<label for="employeeNotes">Notes</label>
				<textarea id="employeeNotes" v-model="dataFields.notes" class="form-control rounded-0" rows="3" />
			</mdbCol>
		</mdbRow>
		<div v-if="(userProfile && userProfile.isAdmin) || dataFields.isApproved" class="custom-control custom-checkbox">
			<input id="isApprovedCheck" v-model="dataFields.isApproved" type="checkbox" class="custom-control-input" />
			<label class="custom-control-label" for="isApprovedCheck">Is Approved?</label>
		</div>
		<mdbRow>
			<mdbCol>
				<mdb-btn type="submit" class="float-right">
					Save
				</mdb-btn>
			</mdbCol>
		</mdbRow>
		<br />
	</validated-form>
</template>

<script>
import ValidatedForm from '../ValidatedForm';
import { mdbInput, mdbBtn, mdbRow, mdbCol, mdbTooltip } from 'mdbvue';
import { mapService } from '../../services/map.service';

export default {
	name: 'CarDealerAccountEmployeeForm',
	components: {
		mdbInput,
		mdbBtn,
		mdbRow,
		mdbCol,
		mdbTooltip,
		ValidatedForm
	},
	props: {
		dataFields: {
			type: Object,
			default: function() {
				return {};
			}
		},
		formMessage: {
			type: Object,
			default: function() {
				return {
					type: "danger",
					message: ""
				};
			}
		}
	},
	data: function() {
		return {
			regions: mapService.getUSStates() || [],
			employmentTypes: ['Full Time', 'Part Time', 'Temporary', 'Contract', 'Other'],
			useTypes: ['Personal', 'Business', 'Other'],
			userProfile: {},
		};
	},
	created() {
		this.userProfile = (window && window.userProfile) || {};
	},
	methods: {
		async handleFormSubmit() {
			this.$emit('submit', this.dataFields);
		},
	},
};
</script>
