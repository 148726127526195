import { DTO } from './dto/database';

const collectionName = 'invited_users';

class InvitedUsersModel extends DTO {
	constructor(collection) {
		super(collection);
	}

	getByEmail(email) {
		return this.list('', 'email', '==', email)
			.then(invitedUser => invitedUser[0]);
	}
}

export const invitedUsersModel = new InvitedUsersModel(collectionName);
