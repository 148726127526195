import { DTO } from './dto/database';
import { companyModel } from './company.model';
import { userService } from '../services/user.service';

const collectionName = 'user_profiles';

class UserProfileModel extends DTO {
	constructor(collection) {
		super(collection);
	}

	getActiveUserAccessibleCompanies() {
		const currentUser = userService.getLoggedInUser();

		return this.getAccessibleCompaniesByUserId(currentUser.uid);
	}

	getActiveUserProfile() {
		const currentUser = userService.getLoggedInUser();

		return this.get(currentUser.uid);
	}

	getAccessibleCompaniesByUserId(userId) {
		return this.get(userId)
			.then(userProfile => {
				if (userProfile.isAdmin) {
					return companyModel.list('name');
				}

				return this.getAccessibleCompanies(userProfile);
			})
			.catch(error => {
				console.log('LIST accessibleCompanies', error);

				return [];
			});
	}

	getAccessibleCompanies(userProfile){
		const accessibleCompanies = userProfile.accessibleCompanies || [];
		const accessibleCompanyIds = accessibleCompanies.map(company => company.id);
		if (!accessibleCompanyIds.length){
			return Promise.resolve([]);
		}

		return companyModel.list('', 'id', 'in', accessibleCompanyIds);
	}
}

export const userProfileModel = new UserProfileModel(collectionName);
