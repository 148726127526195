<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col class="justify-content-center">
					<mdb-card cascade narrow class="mt-5">
						<mdb-card-body>
							<mdb-card-title>Profile: {{ currentUser.email }}</mdb-card-title>
							<users-form :current-user="currentUser" :send-reminder-emails="sendReminderEmails" @saved="savedUser" />
						</mdb-card-body>
					</mdb-card>
				</mdb-col>
			</mdb-row>
		</mdb-container>
	</div>
</template>

<script>
import UsersForm from '../components/UsersForm';
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardTitle, mdbCardBody } from "mdbvue";
import { userService } from '../services/user.service';
import { userProfileModel } from '../models/user-profile.model';

export default {
	name: 'UserProfile',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardTitle,
		mdbCardBody,
		UsersForm
	},
	data () {
		return {
			currentUser: {},
			sendReminderEmails: false
		};
	},
	async created() {
		this.currentUser = userService.getLoggedInUser();
		this.sendReminderEmails = await userProfileModel.get(this.currentUser.uid)
			.then(userProfile => {
				return userProfile.sendReminderEmails;
			});
	},
	methods: {
		async savedUser(user) {
			user.id = this.currentUser.uid;
			await userProfileModel.upsert(user);
			this.$emit('setUser');
		}
	}
}
</script>
<style>

</style>
