<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col class="justify-content-center">
					<employee-table title="Expired Licenses/Insurance" :employees="expiredEmployees" />
				</mdb-col>
			</mdb-row>
			<mdb-row class="mb-3">
				<mdb-col class="justify-content-center">
					<employee-table title="Pending Drivers" :employees="pendingEmployees" />
				</mdb-col>
			</mdb-row>
		</mdb-container>
	</div>
</template>

<script>
import EmployeeTable from '@/components/EmployeeTable';
import { employeeModel } from '../models/employee.model';
import { mdbContainer, mdbRow, mdbCol } from "mdbvue";
import { userProfileModel } from '../models/user-profile.model';

export default {
	name: 'Dashboard',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		EmployeeTable
	},
	data () {
		return {
			pendingEmployees: [],
			expiredEmployees: [],
			accessibleCompanies: [],
		};
	},
	async created() {
		await this.readCompanies();
		const expiredEmployees = await this.listExpired();
		const pendingEmployees = await this.listPending();

		const filteredEmployees = expiredEmployees.filter(this.filterAccessibleEmployees);
		const filteredPendingEmployees = pendingEmployees.filter(this.filterAccessibleEmployees);

		this.expiredEmployees = filteredEmployees;
		this.pendingEmployees = filteredPendingEmployees;
	},
	methods: {
		filterAccessibleEmployees(employee){
			return this.accessibleCompanies.find(company => company.id === employee.companyId);
		},
		listPending() {
			return employeeModel.listPending()
				.catch(error => {
					console.log('listPending', error);

					return [];
				});
		},
		listExpired() {
			return employeeModel.listAllExpired()
				.catch(error => {
					console.log('listExpired', error);

					return [];
				});
		},
		readCompanies() {
			userProfileModel.getActiveUserAccessibleCompanies()
				.then(companies => {
					this.accessibleCompanies = companies;
				})
				.catch(error => {
					console.log('ERROR: company list', error)
				});
		}
	}
}
</script>
<style>

</style>
