<template>
	<div>
		<mdb-container class="mt-5 p-3 pt-5">
			<mdb-row class="mb-3">
				<mdb-col class="justify-content-center">
					<mdb-card cascade narrow class="mt-5">
						<mdb-card-body>
							<mdb-card-title>Finish Sign up: {{ currentUser.email }}</mdb-card-title>
							<div class="mt-3">
								<p>Add a password and name to your account.</p>
							</div>
							<div v-if="errorMessage">
								<br />
								<span class="red-text">{{ errorMessage }}</span>
								<br />
							</div>
							<users-form :current-user="currentUser" @saved="savedUser" />
						</mdb-card-body>
					</mdb-card>
				</mdb-col>
			</mdb-row>
		</mdb-container>
	</div>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardTitle, mdbCardBody } from "mdbvue";
import { userService } from '../services/user.service';
import UsersForm from '../components/UsersForm';
import { invitedUsersModel } from '../models/invited-users.model';
import { userProfileModel } from '../models/user-profile.model';

export default {
	name: 'InvitedUser',
	components: {
		mdbContainer,
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardTitle,
		mdbCardBody,
		UsersForm
	},
	data () {
		return {
			currentUser: {},
			errorMessage: '',
		};
	},
	async created() {
		const urlParams = new URLSearchParams(window.location.search);
		const invitedEmail = urlParams.get('email');

		userService.signInWithEmailLink(invitedEmail, window.location.href)
			.then(async response => {
				const inviteRecord = await invitedUsersModel.getByEmail(invitedEmail);
				if (inviteRecord) {
					this.currentUser = response.user;
					inviteRecord.userId = this.currentUser.uid;
					await invitedUsersModel.upsert(inviteRecord);

					inviteRecord.userId = this.currentUser.uid;
					inviteRecord.id = this.currentUser.uid;
					inviteRecord.email = this.currentUser.email;
					inviteRecord.displayName = this.currentUser.displayName;
					inviteRecord.sendReminderEmails = true;

					await userProfileModel.upsert(inviteRecord);
				}

				throw new Error('Invite Record Not Found');
			})
			.catch(error => {
				const currentUser = userService.getLoggedInUser();
				if (currentUser) {
					this.currentUser = currentUser;
				} else {
					this.errorMessage = error.message || 'Invite link is expired or invalid.';
				}
			});
	},
	methods: {
		async savedUser(user) {
			this.$router.push('/');
			this.$emit('setUser', user);
		}
	}
}
</script>
<style>

</style>
