<template>
	<section id="tables">
		<mdb-row>
			<mdb-col md="12">
				<mdb-card cascade narrow class="mt-5">
					<mdb-view class="gradient-card-header elegant-color-dark">
						<h4 class="h4-responsive text-white">
							{{ title }}
						</h4>
					</mdb-view>
					<mdb-card-body>
						<slot name="header" />
						<mdb-tbl responsive class="table table-striped">
							<thead>
								<tr>
									<th v-for="(head) in headers" :key="head">
										{{ head }}
									</th>
								</tr>
							</thead>
							<slot name="rows" />
						</mdb-tbl>
					</mdb-card-body>
				</mdb-card>
			</mdb-col>
		</mdb-row>
	</section>
</template>

<script>
import { mdbRow, mdbCol, mdbCard, mdbView, mdbCardBody, mdbTbl } from "mdbvue";

export default {
	name: "Table",
	components: {
		mdbRow,
		mdbCol,
		mdbCard,
		mdbView,
		mdbCardBody,
		mdbTbl
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		headers: {
			type: Array,
			default: () => {
				return [];
			}
		},
	},
	data() {
		return {};
	}
};
</script>
<style>
.card.card-cascade .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card.card-cascade h3,
.card.card-cascade h4 {
  margin-bottom: 0px;
}
</style>
