<template>
	<form v-bind="$attrs" @submit.prevent="validate()">
		<mdb-alert
			v-if="formMessage.message"
			:color="formMessage.type"
			dismiss
			@closeAlert="clearError()"
		>
			{{ formMessage.message }}
		</mdb-alert>
		<slot />
	</form>
</template>
<script>
import { mdbAlert } from "mdbvue";
export default {
	name: "ValidatedForm",
	components: {
		mdbAlert
	},
	props: {
		message: {
			type: Object,
			default: function() {
				return {
					type: "danger",
					message: ""
				};
			}
		}
	},
	data() {
		return {
			formMessage: this.message
		};
	},
	watch: {
		message: function(newVal) {
			this.formMessage = newVal;
		}
	},
	methods: {
		validate() {
			this.$emit("submit");
		},
		clearError() {
			this.formMessage = {};
		}
	}
};
</script>
