<template>
	<validated-form :message="formMessage" @submit="handleFormSubmit">
		<mdb-input
			v-model="dataFields.email"
			label="Email"
			type="email"
			outline
			required
		/>
		<div class="form-group">
			<label>Companies</label>
			<multiselect
				v-model="dataFields.accessibleCompanies"
				placeholder="Search for a company"
				label="name"
				track-by="id"
				:options="companies"
				:multiple="true"
				:taggable="true"
			/>
		</div>
		<mdb-btn type="submit" class="float-right">
			Invite
		</mdb-btn>
	</validated-form>
</template>

<script>
import Multiselect from "vue-multiselect";
import ValidatedForm from './ValidatedForm';
import { mdbInput, mdbBtn } from 'mdbvue';
import { userService } from '../services/user.service';

export default {
	name: 'UserForm',
	components: {
		mdbInput,
		mdbBtn,
		Multiselect,
		ValidatedForm
	},
	props: {
		companies: {
			type: Array,
			default: function() {
				return [];
			}
		},
		formData: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			formMessage: {},
			dataFields: { ...this.formData }
		};
	},
	methods: {
		handleFormSubmit() {
			this.formMessage = {};

			userService.sendEmailInvite(this.dataFields.email)
				.then(() => {
					this.formMessage = {
						type: 'success',
						message: 'User Invited!'
					};

					this.dataFields.email = this.dataFields.email.toLowerCase();
					this.dataFields.sendReminderEmails = true;
					this.$emit('saved', this.dataFields);
					this.dataFields = {};
				})
				.catch(error => {
					this.formMessage = {
						type: 'danger',
						message: error.message || 'There was a problem updating. Please try again'
					};
				});
		}
	}
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
