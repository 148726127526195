<template>
	<car-dealer-account-employee-form v-if="companyRecord.accountType === 'auto dealer'" :data-fields="dataFields" :form-message="formMessage" @submit="handleFormSubmit" />
	<pizza-account-employee-form v-else :data-fields="dataFields" :form-message="formMessage" @submit="handleFormSubmit" />
</template>

<script>
import moment from 'moment';
import PizzaAccountEmployeeForm from './employee-forms/PizzaAccountEmployeeForm';
import CarDealerAccountEmployeeForm from './employee-forms/CarDealerAccountEmployeeForm';
import { employeeModel, employeeFields } from '../models/employee.model';
import { companyModel } from '../models/company.model';
import { locationModel } from '../models/location.model';

export default {
	name: 'EmployeeForm',
	components: {
		PizzaAccountEmployeeForm,
		CarDealerAccountEmployeeForm
	},
	props: {
		companyId: {
			type: String,
			default: ''
		},
		locationId: {
			type: String,
			default: ''
		},
		formData: {
			type: Object,
			default: function() {
				return { ...employeeFields };
			}
		}
	},
	data() {
		return {
			formMessage: {},
			dataFields: this.formatEmployee(this.formData),
			companyRecord: {},
		};
	},
	async created() {
		this.companyRecord = await this.readCompany(this.companyId);
	},

	methods: {
		async handleFormSubmit(data) {
			this.formMessage = {};

			if (!this.locationId) {
				this.formMessage = {
					type: 'danger',
					message: 'Company Id is required'
				};

				return;
			}

			const employeeFormFields = { ...data };
			employeeFormFields.companyId = this.companyId;
			employeeFormFields.locationId = this.locationId;

			const [company, locationName ] = await Promise.all([this.readCompany(this.companyId), this.readLocationName(this.locationId)]);

			employeeFormFields.companyName = company.name;
			employeeFormFields.locationName = locationName;
			employeeFormFields.insuranceExpireDate = employeeFormFields.insuranceExpireDate ? moment(employeeFormFields.insuranceExpireDate).toDate(): '';

			employeeFormFields.licenseExpireDate = employeeFormFields.licenseExpireDate ? moment(employeeFormFields.licenseExpireDate).toDate() : '';
			employeeFormFields.birthDate = employeeFormFields.birthDate ? moment(employeeFormFields.birthDate).toDate() : '';
			employeeFormFields.MVRDate = employeeFormFields.MVRDate ? moment(employeeFormFields.MVRDate).toDate(): '';
			employeeFormFields.employeeType = 'driver';

			employeeModel
				.upsert(employeeFormFields)
				.then(employee => {
					this.formMessage = {
						type: 'success',
						message: 'Location Saved!'
					};

					try {
						if (!employeeFormFields.id) {
							const sentTo = 'service@sarkhoshins.com';
							employeeModel.sendAdminNewDriverEmail(sentTo, employee);
						}
					} catch (error) {
						console.log('Send email error', error);
					}

					this.dataFields = { ...employeeFields }
					this.$emit('saved', this.dataFields);
				})
				.catch(error => {
					this.formMessage = {
						type: 'danger',
						message: error.message || 'There was a problem updating. Please try again'
					};
				});
		},
		formatDate(dateObject) {
			if (!dateObject) {
				return '';
			}

			let date = dateObject;
			if (typeof dateObject === 'object') {
				date = dateObject.toDate();
			}

			return moment(date).format('YYYY-MM-DD');
		},
		formatEmployee(){
			const dataFields = { ...this.formData };
			dataFields.licenseExpireDate = this.formatDate(dataFields.licenseExpireDate);
			dataFields.insuranceExpireDate = this.formatDate(dataFields.insuranceExpireDate);
			dataFields.birthDate = this.formatDate(dataFields.birthDate);
			dataFields.MVRDate = this.formatDate(dataFields.MVRDate);
			dataFields.employmentType = dataFields.employmentType || '';
			dataFields.useType = dataFields.useType || '';
			dataFields.notes = dataFields.notes || '';

			return dataFields;
		},
		readCompany(companyId) {
			return companyModel.get(companyId)
				.catch(error => {
					console.log('ERROR: company name read', error);

					return {};
				});
		},
		readLocationName(locationId) {
			return locationModel.get(locationId)
				.then(locationId => locationId.name)
				.catch(error => {
					console.log('ERROR: locationId name read', error);

					return '';
				});
		}
	},
};
</script>
