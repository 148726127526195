import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input'
import Router from 'vue-router';
import BadGateway from '@/pages/BadGateway';
import Companies from '@/pages/Companies';
import CompanyProfile from '@/pages/CompanyProfile';
import EmployeeProfile from '@/pages/EmployeeProfile';
import LocationProfile from '@/pages/LocationProfile';
import Dashboard from '@/pages/Dashboard';
import Login from '@/pages/Login';
import UsersInvite from '@/pages/UsersInvite';
import UserProfile from '@/pages/UserProfile';
import User from '@/pages/User';
import GeneralGuidelines from '@/pages/GeneralGuidelines';
import InvitedUser from '@/pages/InvitedUser';
import { userService } from '../services/user.service';

Vue.use(Router);
Vue.use(VueCurrencyInput, {
	globalOptions: { currency: 'USD', locale: 'en' }
});

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/login',
			name: 'Login',
			component: Login
		},
		{
			path: '/dashboard',
			name: 'Dashboard',
			component: Dashboard,
			props: { page: 1 },
			alias: '/'
		},
		{
			path: '/companies',
			name: 'Companies',
			component: Companies,
			props: { page: 2 },
		},
		{
			path: '/companies/:companyId',
			name: 'CompanyProfile',
			props: { page: 2 },
			component: CompanyProfile
		},
		{
			path: '/companies/:companyId/locations/:locationId',
			name: 'LocationProfile',
			props: { page: 2 },
			component: LocationProfile
		},
		{
			path: '/companies/:companyId/locations/:locationId/employees/:employeeId',
			name: 'EmployeeProfile',
			props: { page: 2 },
			component: EmployeeProfile
		},
		{
			path: '/users',
			name: 'UsersInvite',
			component: UsersInvite,
			props: { page: 3 },
		},
		{
			path: '/users-invited',
			name: 'InvitedUser',
			component: InvitedUser,
			props: { page: 3 },
		},
		{
			path: '/profile',
			name: 'UserProfile',
			component: UserProfile,
			props: { page: 4 },
		},
		{
			path: '/users/:userId',
			name: 'User',
			component: User,
			props: { page: 4 },
		},
		{
			path: '/general-guidelines',
			name: 'GeneralGuidelines',
			component: GeneralGuidelines,
			props: { page: 5 },
		},
		{
			path: '/404',
			name: 'BadGateway',
			component: BadGateway
		},
		{
			path: '*',
			redirect: '/404'
		}
	]
});

router.beforeEach((to, from, next) => {
	const currentUser = userService.getLoggedInUser();
	const currentPath = to.path;
	const publicRoutes = ['/login', '/users-invited']

	if (!(currentUser || publicRoutes.includes(currentPath))) {
		next('login');
	} else {
		next();
	}
});

export default router;
